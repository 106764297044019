import React, { lazy, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useLocation } from "react-router-dom";
import useExternalScript from "./api/useExternalScript";
import LogoLoader from "./components/shared/LogoLoader";
import { AuthProvider } from "./contexts/auth-context";

const AuthenticatedApp = lazy(() => import("./components/App/AuthenticatedApp"));
const PublicApp = lazy(() => import("./components/App/PublicApp"));

export const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      staleTime: Infinity,
      notifyOnChangeProps: ["data", "error", "isLoading", "refetch", "isSuccess"],
    },
    mutations: {},
  },
});

function AppEntry() {
  const isPublicPage = useLocation().pathname.startsWith("/@");
  const isProd = process.env.NODE_ENV === "production";
  useExternalScript(process.env.REACT_APP_GOOGLE_ANALYTICS_SDK_URL, {
    onLoadCallback: () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "G-FT5FYYK0SD");
    },
    isProdOnly: true,
    // fetchAfterInMs: 3000,
  });

  return (
    <Suspense fallback={<LogoLoader />}>
      <QueryClientProvider client={client}>
        {isPublicPage ? <PublicApp /> : <AuthenticatedApp />}
        {!isProd && (
          <div className="print-hidden">
            <ReactQueryDevtools initialIsOpen={false} />
          </div>
        )}
      </QueryClientProvider>
    </Suspense>
  );
}

export default () => (
  <AuthProvider>
    <AppEntry />
  </AuthProvider>
);
