import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

function Button(props) {
  let loaderCss = "mr-1 ";
  if (props.variant === "text" || props.variant?.includes("light")) {
    loaderCss += "loader--is-primary";
  } else {
    loaderCss += "loader load-complete";
  }
  const ref = useRef(null);
  const [stopDoneStatus, setStopDoneStatus] = useState(false);

  useEffect(() => {
    if (props.isDone) {
      ref.current = setTimeout(() => {
        setStopDoneStatus(true);
      }, [750]);
      return () => clearTimeout(ref.current);
    } else {
      setStopDoneStatus(false);
    }
  }, [props.isDone]);

  const onButtonClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.onClick(e);
  };

  return (
    <>
      {props.isDone && !stopDoneStatus ? (
        <button
          type="button"
          className={classNames("btn btn-light shadow-none border-none text-success scale-up-center", props.className)}
        >
          <span className="text-success">✓ </span>
          {props.doneLabel}
        </button>
      ) : (
        <button
          type="button"
          title={props.title}
          className={classNames(
            "btn scale-up-center-sm print-hidden",
            {
              "btn-light": props.variant === "light",
              "btn-light border shadow-none": props.variant === "light-2",
              "btn-primary-light shadow-none": props.variant === "light--primary",
              "btn-danger": props.variant === "danger",
              "btn-light text-danger": props.variant === "light--danger",
              "btn-light text-secondary": props.variant === "light--secondary",
              "btn-light text-dark": props.variant === "light--dark",
              "btn-primary": props.variant === "primary",
              "btn-dark": props.variant === "dark",
              "btn-outline-dark": props.variant === "dark--outlined",
              "btn-outline-primary": props.variant === "primary--outlined",
              "btn-light shadow-none border-none": props.variant === "text",
              "btn-light shadow-none border-none text-primary": props.variant === "text--primary",
              "btn-light shadow-none border-none text-secondary": props.variant === "text--secondary",
              "btn-light shadow-none border-none text-dark": props.variant === "text--dark",
              "btn-secondary": props.variant === "secondary",
              "btn-light border-none shadow-none text-dark": props.variant === "link",
              "btn-light border-none shadow-none text-primary": props.variant === "link--primary",
              "btn-light border-none shadow-none text-secondary": props.variant === "link--secondary",
              "btn-sm": props.size === "small",
              "btn-lg h6 font-weight-medium": props.size === "large",
              "btn-icon": props.hasIcon,
              disabled: props.loading || props.disabled,
            },
            props.className
          )}
          onClick={(e) => !props.disabled && onButtonClick(e)}
          disabled={props.loading || props.disabled}
        >
          {props.loading && <span className={loaderCss} />}
          <span>{props.children ?? props.label}</span>
        </button>
      )}
    </>
  );
}

Button.defaultProps = {
  label: "Button",
  loading: false,
  variant: "light",
  size: "regular",
  hasIcon: false,
  renderLabelOnMobile: false,
  isDone: false,
  doneLabel: "Success",
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.string,
  hasIcon: PropTypes.bool,
  variant: PropTypes.oneOf([
    "light",
    "primary",
    "primary--outlined",
    "light--primary",
    "light--secondary",
    "light--danger",
    "text",
    "text--primary",
    "text--secondary",
    "dark",
    "secondary",
    "danger",
    "link",
    "link--primary",
    "link--secondary",
  ]),
  size: PropTypes.oneOf(["regular", "small", "large"]),
  renderLabelOnMobile: PropTypes.bool,
  title: PropTypes.string,
  isDone: PropTypes.bool,
  doneLabel: PropTypes.string,
};

export default Button;
