import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { BrowserRouter as Router } from "react-router-dom";
import smoothScroll from "smoothscroll-polyfill";
import firebaseConfig from "./constants/firebaseConfig";
import firebase from "firebase/app";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ErrorBoundaryFallback from "./components/shared/ErrorBoundaryFallback";
import { LOCAL_STORAGE_SELF_HANDLE, LOCAL_STORAGE_SESSION_TOKEN } from "./constants/localStorageConstants";
import amplitude from "amplitude-js";

// initiliaze amplitude
amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY);
window.amplitude = amplitude;

// initialize firebase app
export const firebaseApp = firebase.initializeApp(firebaseConfig);

smoothScroll.polyfill();

const isProdEnv = process.env.NODE_ENV === "production";

if (isProdEnv) {
  console.log("Launching the Rocket 🚀 ... ");
  // initialize google analytics
  // ReactGA.initialize(process.env.REACT_APP_GA);
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

function RawRoot() {
  return (
    <Router>
      <App />
    </Router>
  );
}

const RootComponent = isProdEnv
  ? Sentry.withErrorBoundary(RawRoot, {
      beforeCapture: (scope) => {
        scope.setTag("session", localStorage.getItem(LOCAL_STORAGE_SESSION_TOKEN));
        scope.setTag("handle", localStorage.getItem(LOCAL_STORAGE_SELF_HANDLE));
      },
      fallback: { ErrorBoundaryFallback },
    })
  : RawRoot;

ReactDOM.render(<RootComponent />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
