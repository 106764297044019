export default {
  apiKey: "AIzaSyAYdk_T8qSHiZAZKmSEAsI-9ADSGmBbd4M",
  authDomain: "auth.swiftcv.com",
  databaseURL: "https://chromatic-pride-282212.firebaseio.com",
  projectId: "chromatic-pride-282212",
  storageBucket: "chromatic-pride-282212.appspot.com",
  messagingSenderId: "135338606863",
  appId: "1:135338606863:web:5f89197f605fde08d3fe7a",
  measurementId: "G-FT5FYYK0SD",
};
