import { useEffect, useState } from "react";

export const STATES = {
  LOADING: "loading",
  IDLE: "idle",
  READY: "ready",
  ERROR: "error",
};

const useExternalScript = (
  url,
  {
    isProdOnly = false,
    scriptType = "application/javascript",
    attrs = {},
    onLoadCallback = () => {},
    onErrorCallback = () => {},
    doFetch = true,
    fetchAfterInMs = 0,
  } = {}
) => {
  let [state, setState] = useState(url ? STATES.LOADING : STATES.IDLE);
  const isProd = process.env.NODE_ENV === "production";
  const toFetchScript = (isProdOnly ? isProd : true) && doFetch;

  attrs = Object.assign(
    {
      defer: true,
    },
    attrs
  );

  useEffect(() => {
    if (!url || !toFetchScript) {
      setState(STATES.IDLE);
      return;
    }

    let scriptEl = document.querySelector(`script[src="${url}"]`);

    const handleScript = (e) => {
      const isSuccess = e.type === "load";
      setState(isSuccess ? STATES.READY : STATES.ERROR);
      if (isSuccess) {
        onLoadCallback(e);
      } else {
        onErrorCallback(e);
      }
    };

    if (!scriptEl) {
      scriptEl = document.createElement("script");
      scriptEl.type = scriptType;
      scriptEl.src = url;
      for (let key in attrs) {
        scriptEl.setAttribute(key, attrs[key]);
      }
      setTimeout(() => {
        document.body.appendChild(scriptEl);
      }, fetchAfterInMs);
    }

    scriptEl.addEventListener("load", handleScript);
    scriptEl.addEventListener("error", handleScript);

    return () => {
      scriptEl.removeEventListener("load", handleScript);
      scriptEl.removeEventListener("error", handleScript);
    };
  }, [url, toFetchScript]);

  return state;
};

export default useExternalScript;
