import React from "react";
import ErrorPageIcon from "../../assets/error-page.svg";
import Button from "./Button";
import Modal from "./Modal";

export default function ErrorBoundaryFallback() {
  const refreshWindow = () => {
    window.location.reload();
  };

  return (
    <Modal>
      <div className="p-5">
        <ErrorPageIcon className="mb-3 fill-danger" width={48} height={48} />
        <h4 className="font-weight-medium mb-1">Something is broken.</h4>
        <p className="text-muted mw-480">
          We apologize for the inconvenience, our team has been notified about this event. Meanwhile, please try again
          and see if it works.
        </p>
        <Button className="w-100 mt-3" variant="primary" onClick={refreshWindow}>
          Refresh Window
        </Button>
      </div>
    </Modal>
  );
}
