import React from "react";
import PropTypes from "prop-types";
import Portal from "../Portal";
import css from "./modal.module.scss";
import classNames from "classnames";

function Modal(props) {
  React.useEffect(() => {
    const el = document.querySelector("body");
    el.style.overflow = "hidden";
    return () => {
      el.style.overflow = "auto";
    };
  }, []);
  return (
    <Portal>
      <div className={css.modal}>
        <div className={classNames([css.modal__bg], { [css["modal__bg--defaultBg"]]: !props.transparentBg })}>
          <div
            className={classNames(
              css.modal__window,
              {
                [css["modal__window--mobileFullScreen"]]: props.fullScreenOnMobile,
              },
              props.modalClasses
            )}
          >
            {props.children}
          </div>
        </div>
      </div>
    </Portal>
  );
}

Modal.defaultProps = {
  fullScreenOnMobile: true,
  transparentBg: false,
  modalClasses: "",
};

Modal.propTypes = {
  children: PropTypes.any,
  fullScreenOnMobile: PropTypes.bool,
  transparentBg: PropTypes.bool,
  modalClasses: PropTypes.string,
};

export default Modal;
