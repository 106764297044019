import React from "react";
import PropTypes from "prop-types";
import Logo from "../../../assets/logo-pink.svg";
import "./logo-loader.scss";

const getRandomLt33 = () => Math.floor(Math.random() * 33 + 1);

function LogoLoader(props) {
  // const [progress, setProgress] = React.useState(20);
  // const ref = React.useRef(null);

  // React.useEffect(() => {
  //   ref.current = setInterval(() => {
  //     setProgress((currProgress) => {
  //       const newProgress = currProgress + getRandomLt33();
  //       if (newProgress > 95) {
  //         return currProgress;
  //       }
  //       return newProgress;
  //     });
  //   }, 250);
  //   return () => clearInterval(ref.current);
  // }, []);

  return (
    <div className="logo-loader">
      <div className="logo-loader__content">
        <Logo
          className="animate-bounce w-24 h-24"
          style={{
            animationDuration: "800ms",
          }}
        />
        <div className="w-10 h-1 bg-gray-50 rounded-full relative -bottom-1 -left-2 animate-pulse" />
        <div
          className="w-16 animate-ping border-gray-100 shadow-lg rounded-full relative -left-2"
          style={{
            animationDelay: "400ms",
            animationDuration: "800ms",
            animationTimingFunction: "linear",
            borderBottomWidth: "3px",
          }}
        />
        {/* <progress max="100" value={progress} className="rounded-3xl animate-ping"></progress> */}
      </div>
    </div>
  );
}

LogoLoader.propTypes = {};

export default LogoLoader;
