import React, { createContext, useContext, useState } from "react";

import { LOCAL_STORAGE_SESSION_TOKEN } from "../constants/localStorageConstants";

export const ctx = createContext({
  isAuthenticated: false,
  sessionToken: null,
});

export function useAuth(selector) {
  const data = useContext(ctx);
  return selector ? selector(data) : data;
}

export function AuthProvider(props) {
  const [isAuthenticated, setAuthenticated] = useState(!!localStorage.getItem(LOCAL_STORAGE_SESSION_TOKEN));
  const { Provider } = ctx;
  const sessionToken = localStorage.getItem(LOCAL_STORAGE_SESSION_TOKEN);
  return <Provider value={{ sessionToken, isAuthenticated, setAuthenticated }} {...props} />;
}

export default { AuthProvider, useAuth };
